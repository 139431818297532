import type { AxiosResponse } from 'axios';
import type { Context } from '@nuxt/types';
import { getAccessToken, getFullUrl, getSubId } from '~/utils/index';
import {
  ADD_FAVORITE,
  GET_AB_TITLE_BY_ARTICLE,
  GET_FOLDERS,
  REMOVE_FAVORITE,

} from '~/constants';
import { isAnyRecipePage } from '~/router';
import LoginModal from '~/components/LoginModal.vue';
import { pushBadge } from '~/components/Pusher/websockets-functions';

type Post = {
  title: string;
  url: string;
  id: number;
  type?: string;
}

// в данный момент идет работа с избранным
let isManagingFavorites = false;
// отложить добавление в избранное
export function favoriteDeferred (deferredAction: Function, ctx: any) {
  ctx.$modal.showModal(LoginModal, {
    name: 'LoginModal',
    container: 'windows',
    transition: 'modal-fade',
    disableScroll: true,
  }, {
    loginCallback: deferredAction.bind(ctx),
    eventElement: 'Избранное',
  });
};

// удалить избранное
export function favoriteDelete (favorite: IFavoriteItem, post: Post, ctx: any, sendGTM: boolean = true, cb: Function = () => {}): void {
  if (isManagingFavorites) {
    return;
  }
  isManagingFavorites = true;

  const abTestTitle = ctx.$store.getters[GET_AB_TITLE_BY_ARTICLE](post.id);

  return ctx.$authApi.removeArticleFromFavorites(favorite.id, getSubId(), getAccessToken())
    .then((resp: AxiosResponse) => {
      if (resp.data.success) {
        ctx.$store.commit(REMOVE_FAVORITE, { postId: post.id });
        if (sendGTM) {
          sendRemoveFavoriteEvent(post, ctx);
        }
        const title = post ? ` <b>${abTestTitle || post.title}</b> ` : ' ';
        const notificationMessage = isAnyRecipePage(ctx.$route.name) || post.type === 'recipe'
          ? `Рецепт${title}удален из избранного`
          : `Статья${title}удалена из избранного`;
        return pushBadge(ctx.$store.dispatch, {
          content: notificationMessage,
        });
      }
    })
    .then(() => {
      return ctx.$store.dispatch(GET_FOLDERS);
    })
    .then(() => {
      cb();
    })
    .finally(() => {
      isManagingFavorites = false;
    });
};

// добавить статью в папку избранного
export function favoriteAddToFolder (folder: IFolderItem, post: Post, sendGTM: boolean = true, ctx: any, isNewFolder: boolean = false): any {
  if (isManagingFavorites) {
    return;
  }
  isManagingFavorites = true;

  const abTestTitle = ctx.$store.getters[GET_AB_TITLE_BY_ARTICLE](post.id);

  return ctx.$authApi.addArticleToFavorites(post.id, folder.id, getSubId(), getAccessToken())
    .then((resp: AxiosResponse) => {
      if (resp.data.success) {
        const { data } = resp.data;

        ctx.$store.commit(ADD_FAVORITE, { favorite: data });

        if (!isNewFolder && sendGTM) {
          sendAddFavoriteEvent(post, ctx);
        }

        const title = post ? ` <b>${abTestTitle || post.title}</b> ` : ' ';
        const notificationMessage = isAnyRecipePage(ctx.$route.name) || post.type === 'recipe'
          ? `Рецепт${title}добавлен в папку <b>${folder.name}</b>`
          : `Статья${title}добавлена в папку <b>${folder.name}</b>`;
        return pushBadge(ctx.$store.dispatch, {
          content: notificationMessage,
          clickHandler: () => {
            // window.location.href = process.env.WORDPRESS_API_URL + '/profile/favorites';

            const {
              $store: {
                state: {
                  user: {
                    integer_id,
                  },
                },
              },
            } = ctx;

            if (integer_id) {
              location.href = `/profile/user/${integer_id}/favorites`;
            }
          },
        });
      }
    })
    .then(() => {
      return ctx.$store.dispatch(GET_FOLDERS);
    })
    .finally(() => {
      isManagingFavorites = false;
    });
};

// добавить папку к избранному и добавить в нее пост
export function favoriteCreateFolderAndAdd (folderName: string, post: Post, ctx: any, sendGTM: boolean = true, cb: Function = () => {}) {
  if (isManagingFavorites) {
    return;
  }
  isManagingFavorites = true;

  return ctx.$authApi.addFolder(folderName, getSubId(), getAccessToken())
    .then((resp: AxiosResponse) => {
      if (resp.data.success) {
        const { data: folder } = resp.data;

        isManagingFavorites = false;

        if (sendGTM) {
          sendAddFavoriteInNewFolderEvent(post, ctx);
        }

        favoriteAddToFolder(folder, post, false, ctx, true)
          .then(() => {
            cb();
          });
      }
    });
}

// добавить папку к избранному и перенести в нее пост
export function favoriteCreateFolderAndTransfer (folderName: string, favorite: IFavoriteItem, post: Post, _: void, ctx: any, cb: Function = () => {}) {
  if (isManagingFavorites) {
    return;
  }
  isManagingFavorites = true;

  return ctx.$authApi.addFolder(folderName, getSubId(), getAccessToken())
    .then((resp: AxiosResponse) => {
      if (resp.data.success) {
        const { data: folder } = resp.data;

        isManagingFavorites = false;
        favoriteTransferToFolder(favorite, folder, post, undefined, ctx)
          .then(() => {
            cb();
          });
      }
    });
};

// перенести избранное из одной папки в другую
export function favoriteTransferToFolder (favorite: IFavoriteItem, folder: IFolderItem, post: Post, _: void, ctx: any, cb: Function = () => {}): any {
  if (isManagingFavorites) {
    return;
  }
  isManagingFavorites = true;

  const abTestTitle = ctx.$store.getters[GET_AB_TITLE_BY_ARTICLE](post.id);

  return ctx.$authApi.patchFavorite(favorite.id, folder.id, getSubId(), getAccessToken())
    .then((resp: AxiosResponse) => {
      if (resp.data.success) {
        const { data: favorite } = resp.data;
        cb();

        ctx.$store.commit(REMOVE_FAVORITE, { postId: favorite.post_id });
        ctx.$store.commit(ADD_FAVORITE, { favorite });

        const title = post ? ` <b>${abTestTitle || post.title}</b> ` : ' ';
        const notificationMessage = isAnyRecipePage(ctx.$route.name) || post.type === 'recipe'
          ? `Рецепт${title}перемещен в папку <b>${folder.name}</b>`
          : `Статья${title}перемещена в папку <b>${folder.name}</b>`;

        return pushBadge(ctx.$store.dispatch, {
          content: notificationMessage,
          clickHandler: () => {
            // window.location.href = process.env.WORDPRESS_API_URL + '/profile/favorites';

            const {
              $store: {
                state: {
                  user: {
                    integer_id,
                  },
                },
              },
            } = ctx;

            if (integer_id) {
              location.href = `/profile/user/${integer_id}/favorites`;
            }
          },
        });
      }
    })
    .then(() => {
      return ctx.$store.dispatch(GET_FOLDERS);
    })
    .finally(() => {
      isManagingFavorites = false;
    });
};

// ------------------------
// аналитика для избранного
// ------------------------
// https://youtrack.lifehacker.ru/issue/LH-894
// Добавление материала в избранное
// Добавление поста в избранное в кастомную/общую папку
const sendAddFavoriteEvent = (post: Post, ctx: Context): void => {
  ctx.$sendYandexMetrika({
    level1: 'Избранное_добавление избранного',
    level4: getFullUrl(post.url),
    // @ts-ignore
    level6: ctx.$store?.getters?.currentTitle ?? 'unknown',
  });
};

// Удаление поста из избранного
const sendRemoveFavoriteEvent = (post: Post, ctx: Context): void => {
  ctx.$sendYandexMetrika({
    level1: 'Избранное_удаление избранного',
    level4: getFullUrl(post.url),
    // @ts-ignore
    level6: ctx.$store?.getters?.currentTitle ?? 'unknown',
  });
};

// Добавление поста в новую папку
// когда папка создана перед добавлением поста в избранное
const sendAddFavoriteInNewFolderEvent = (post: Post, ctx: Context): void => {
  ctx.$sendYandexMetrika({
    level1: 'Избранное_добавление избранного',
    level4: getFullUrl(post.url),
    // @ts-ignore
    level6: ctx.$store?.getters?.currentTitle ?? 'unknown',
  });
};
